@media (min-width: 740px) {
    .hello {
        display: 'flex';
        flex-direction: column;
        margin-top: '10rem';
        color: 'black'
    }
    #getStartedButton {
        display: 'flex';
        margin-bottom: '1rem'
    }
    .github-star {
        display: 'flex';
        margin: '10rem'
    }
  }

  