/* Add this CSS to your existing styles or create a new CSS file */

/* Container for the grid */
.order-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr)); /* Adjust the minmax values as needed */
    gap: 16px; /* Adjust the gap between items as needed */
  }
  
  /* Style for each order item */
  .order-item {
    border: 1px solid #ccc;
    padding: 16px;
    border-radius: 8px;
    background-color: #f0f0f0;
  }
  
  /* Responsive styling */
  @media (max-width: 767px) {
    .order-grid {
      grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    }
  }
  
/* In your CSS file or style section */
.pagination-btn {
  background-color: #3498db;
  color: #fff;
  padding: 8px 16px;
  margin: 0 4px;
  cursor: pointer;
  border-radius: 4px;
}

.pagination-btn:disabled {
  background-color: #d3d3d3;
  color: #555;
  cursor: not-allowed;
}

.pagination-btn.active {
  background-color: #3498db;
  color: #fff;
}
